import { createStyles, makeStyles, Theme } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import React, { CSSProperties, useEffect } from 'react';
import {
  IQ_API_ENDPOINT,
  IQ_API_KEY,
} from './../../constants/config';

interface IQCodePreviewProps {
  css?: string;
  javascript?: string;
  php?: string;
  acfDefinition?: string;
  style?: CSSProperties;
  className?: string;
  scrolling?: boolean;
}

export const IQCodePreview = (props: IQCodePreviewProps) => {
  const classes = useStyles();
  const [srcDoc, setSrcDoc] = React.useState('');

  useEffect(() => {
    axios
      .post(
        IQ_API_ENDPOINT,
        qs.stringify({
          css: props.css,
          php: props.php,
          javascript: props.javascript,
          api_key: IQ_API_KEY,
          acf_definition: props.acfDefinition,
        }),
      )
      .then(function (response) {
        setSrcDoc(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    return () => {};
  }, [props.acfDefinition, props.css, props.javascript, props.php]);
  return (
    <iframe
      scrolling={props.scrolling ? 'yes' : 'no'}
      title={'IQCodePreview'}
      srcDoc={srcDoc}
      className={[classes.iqPreview, props.className].join(' ')}
      style={props.style}
    ></iframe>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iqPreview: {
      width: '100%',
      minHeight: '90vh',
      maxHeight: '90vh',
      border: '1px solid #fafafa',
    },
  }),
);
