import phpPlugin from '@prettier/plugin-php/standalone';
import { Options } from 'prettier';
import babylon from 'prettier/parser-babel';
import htmlPlugin from 'prettier/parser-html';
import postCss from 'prettier/parser-postcss';
import prettier from 'prettier/standalone';

export const prettify = (
  code: string,
  language:
    | 'css'
    | 'javascript'
    | 'php'
    | 'json'
    | 'html'
    | 'markdown',
) => {
  let prettierConfig: Options = {};
  if (language === 'css') {
    prettierConfig = {
      parser: 'css',
      plugins: [postCss],
    };
  } else if (language === 'javascript') {
    prettierConfig = {
      parser: 'babel',
      plugins: [babylon],
    };
  } else if (language === 'php') {
    prettierConfig = {
      parser: 'php',
      plugins: [phpPlugin],
    };
  } else if (language === 'json') {
    prettierConfig = {
      parser: 'json',
      plugins: [babylon],
    };
  } else if (language === 'html') {
    prettierConfig = {
      parser: 'html',
      plugins: [htmlPlugin],
    };
  }
  let retVal: string;
  try {
    retVal = prettier.format(code, prettierConfig);
  } catch (error) {
    retVal = code;
  }
  return retVal;
};
