import {
  Button,
  CardMedia,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withFirebase } from '../../components/Firebase';
import { withFirebaseProps } from '../../components/Firebase/withFirebase';
import * as ROUTES from '../../constants/routes';
import AuthUserContext from './../../components/Session/context';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginForm: {
      minHeight: '100vh',
    },
    formContainer: {
      padding: '60px',
    },
    controlContainer: {
      paddingTop: '120px',
    },
    sliderImage: {
      height: '60vh',
      minHeight: '500px',
    },
    formWrapper: {
      height: '60vh',
      minHeight: '500px',
    },
    submitBtnContainer: {
      textAlign: 'center',
      marginTop: '40px',
    },
  }),
);

const SignInForm = (
  props: Props & withFirebaseProps & RouteComponentProps,
) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const user = useContext(AuthUserContext);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    props.firebase
      .doSignInWithEmailAndPassword(username, password)
      .then(() => {
        props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.message, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      });

    event.preventDefault();
  };

  useEffect(() => {
    if (user !== null) {
      props.history.push(ROUTES.HOME);
    }
    return () => {};
  }, [props.history, user]);

  return (
    <React.Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.loginForm}
      >
        <Grid item xs={8} lg={6}>
          <Paper
            variant="elevation"
            elevation={2}
            className={classes.formWrapper}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.formContainer}
              >
                <Grid item>
                  <Typography
                    component="p"
                    variant="button"
                    align="center"
                  >
                    Welcome back to Eloq iQ
                  </Typography>
                  <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                  >
                    Prepare yourself!
                  </Typography>
                </Grid>
                <Grid item className={classes.controlContainer}>
                  <form onSubmit={onSubmit}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      spacing={4}
                    >
                      <Grid item>
                        <TextField
                          type="email"
                          label="Username"
                          fullWidth
                          name="username"
                          value={username}
                          onChange={(e) =>
                            setUsername(e.target.value)
                          }
                          required
                          autoFocus
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          type="password"
                          label="Password"
                          fullWidth
                          name="password"
                          value={password}
                          onChange={(e) =>
                            setPassword(e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        className={classes.submitBtnContainer}
                      >
                        <Button
                          color="secondary"
                          variant="outlined"
                          type="submit"
                          disabled={
                            password === '' || username === ''
                          }
                        >
                          Enter the future!
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
              {/* Image Container */}
              <Grid item sm={false} md={6}>
                <CardMedia
                  image={
                    'https://images.pexels.com/photos/2086361/pexels-photo-2086361.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
                  }
                  className={classes.sliderImage}
                ></CardMedia>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const SignIn = withRouter(withFirebase(SignInForm));
