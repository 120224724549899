import { Chip, Menu, MenuItem } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { Link } from 'react-router-dom';
import { IQCodePreview } from '../../../components/IQCodePreview';
import { EDITOR } from '../../../constants/routes';
import { Snippet } from './../../../models/Snippet';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    actionButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    iqPreviewWrapper: {
      position: 'relative',
      width: '100%',
      height: 200,
      overflow: 'hidden',
      backgroundColor: '#ffef62',
      borderBottom: '1px solid #eee',
    },
  }),
);

const iqPreviewStyle: React.CSSProperties = {
  boxSizing: 'border-box',
  height: '100%',
  left: '50%',
  minHeight: '750px',
  minWidth: '100%',
  transform: 'translate(-50%, -50%) scale(0.248)',
  borderLeft: '1px solid #eee',
  borderRight: '1px solid #eee',
  borderTop: '1px solid #eee',
  position: 'absolute',
  top: 'calc(50% + 10px)',
  width: 1200,
  pointerEvents: 'none',
};

interface Props {
  data: Snippet;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const SnippetCard = (props: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={props.onClick}>
        <div className={classes.iqPreviewWrapper}>
          <IQCodePreview
            php={props.data.content?.php}
            javascript={props.data.content?.javascript}
            css={props.data.content?.css}
            acfDefinition={props.data.content?.acfDefinition}
            style={iqPreviewStyle}
          />
        </div>

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.data.title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {props.data.tags?.map((tag) => {
          return <Chip key={tag} label={tag} />;
        })}
      </CardActions>
      <IconButton
        aria-label="settings"
        className={classes.actionButton}
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: 'black', opacity: 0.65 }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            if (props.onEdit) props.onEdit();
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem>
          <Link
            to={EDITOR(props.data.uid)}
            style={{
              textDecoration: 'none',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            LiveEdit
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (props.onDelete) props.onDelete();
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Card>
  );
};
