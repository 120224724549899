import app from 'firebase/app';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withFirebaseProps } from './../Firebase/withFirebase';
import AuthUserContext from './context';

interface withAuthorizationProps {}

interface withAuthorizationState {}

function withAuthorization<T extends withAuthorizationProps>(
  condition: (user: app.User | null) => boolean,
  Component: React.ComponentType<T>,
) {
  class WithAuthorization extends React.Component<
    T & withFirebaseProps & RouteComponentProps,
    withAuthorizationState
  > {
    listener!: () => void;

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser) => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.SIGN_IN);
          }
        },
        () => this.props.history.push(ROUTES.SIGN_IN),
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(withFirebase(WithAuthorization));
}

export default withAuthorization;
