import React from 'react';
import Firebase, { FirebaseContext } from '.';

export interface withFirebaseProps {
  firebase: Firebase;
}
export function withFirebase<P>(
  Component: React.ComponentType<P & withFirebaseProps>,
) {
  const ComponentWithFirebase = (props: P) => {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => (
          <Component
            {...props}
            firebase={firebase ?? new Firebase()}
          />
        )}
      </FirebaseContext.Consumer>
    );
  };

  return ComponentWithFirebase;
}
