import DialogContentText from '@material-ui/core/DialogContentText';
import React from 'react';
import { IQDialog } from '../../../components/IQDialog';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const SnippetDialogDelete = (props: Props) => {
  return (
    <IQDialog
      title="Delete"
      cancelText="Cancel"
      submitText="Delete"
      onClose={props.onClose}
      open={props.open}
      onSubmit={props.onSubmit}
    >
      <DialogContentText>
        Are you sure, this snippet will be for every lost!
      </DialogContentText>
    </IQDialog>
  );
};
