import {
  createStyles,
  Fab,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { Content } from '../../components/Content';
import { FirebaseContext } from '../../components/Firebase';
import { Navigation } from '../../components/Navigation';
import { Snippet } from '../../models/Snippet';
import withAuthorization from './../../components/Session/withAuthorization';
import { SnippetCard } from './SnippetCard/index';
import {
  SnippetDialogDelete,
  SnippetDialogModify,
} from './SnippetDialog';
import { SnippetDialogView } from './SnippetDialog/view';
import { SnippetFilter } from './SnippetFilter/index';

interface Props {}

const HomeView = (props: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const firebase = useContext(FirebaseContext);
  const [snippetViewOpen, setSnippetViewOpen] = useState(false);
  const [snippetDeleteOpen, setSnippetDeleteOpen] = useState(false);
  const [snippetModifyOpen, setSnippetModifyOpen] = useState(false);
  const [snippetRef, setSnippetRef] = useState<Snippet | null>(null);
  const [snippets, setSnippets] = useState<Snippet[]>([]);
  const [checkedTags, setCheckedTags] = useState<string[] | null>(
    null,
  );
  const [filteredSnippets, setFilteredSnippets] = useState<
    Snippet[] | null
  >(null);
  const [searchInputValue, setSearchInputValue] = useState('');

  const tags = [
    ...new Set(snippets.map((m) => m.tags ?? '').flat() as string[]),
  ];

  useEffect(() => {
    const unsubscribe = firebase
      ?.snippets()
      .onSnapshot(function (snapshot) {
        let snippets: Snippet[] = [];
        snapshot.forEach((doc) =>
          snippets.push({ ...doc.data(), uid: doc.id }),
        );
        setSnippets(snippets);
      });
    return () => unsubscribe!();
  }, [firebase]);

  const onSnippetDialogModify = (data: Snippet) => {
    if (snippetRef?.uid) {
      firebase
        ?.snippet(snippetRef?.uid)
        .set(data)
        .then(function () {
          console.log('Snippet updated with ID: ', snippetRef?.uid);
          enqueueSnackbar('Snippet updated', {
            variant: 'success',
          });
          setSnippetModifyOpen(false);
        })
        .catch(function (error) {
          enqueueSnackbar('Error updating Snippet', {
            variant: 'error',
          });
          console.error('Error updating Snippet: ', error);
        });
    } else {
      firebase
        ?.snippets()
        .add(data)
        .then(function (docRef) {
          console.log('Snippet created with ID: ', docRef.id);
          enqueueSnackbar('Snippet created', {
            variant: 'success',
          });
          setSnippetModifyOpen(false);
        })
        .catch(function (error) {
          enqueueSnackbar('Error adding Snippet', {
            variant: 'error',
          });
          console.error('Error adding Snippet: ', error);
        });
    }
  };

  return (
    <React.Fragment>
      <Navigation
        searchInputValue={searchInputValue}
        onSearchInputChange={(e) => {
          setSearchInputValue(e.target.value);
          setCheckedTags(tags);
          setFilteredSnippets(
            snippets.filter(
              (s) =>
                s.title
                  ?.toUpperCase()
                  .includes(e.target.value.toUpperCase()) ||
                e.target.value
                  .toUpperCase()
                  .split(' ')
                  .every((t) =>
                    s.tags?.map((k) => k.toUpperCase()).includes(t),
                  ),
            ),
          );
        }}
      />
      <Content>
        <Grid container spacing={3}>
          <Grid item sm={4} xl={3}>
            {/* Snippet Filter */}
            <SnippetFilter
              onFilterChange={(tags) => {
                setCheckedTags(tags);
                setSearchInputValue('');
                setFilteredSnippets(
                  snippets.filter((m) =>
                    m.tags?.some((t) => tags.includes(t) ?? true),
                  ),
                );
              }}
              snippets={snippets}
              tags={tags}
              checkedTags={checkedTags ?? tags}
            />
          </Grid>
          <Grid item sm={8} xl={9}>
            {/* Snippet Grid */}
            <Grid container spacing={3}>
              {(filteredSnippets ?? snippets).map((s) => {
                return (
                  <Grid key={s.uid} item md={6} lg={4} xl={3}>
                    <SnippetCard
                      onClick={() => {
                        setSnippetRef(s);
                        setSnippetViewOpen(true);
                      }}
                      onDelete={() => {
                        setSnippetRef(s);
                        setSnippetDeleteOpen(true);
                      }}
                      onEdit={() => {
                        setSnippetRef(s);
                        setSnippetModifyOpen(true);
                      }}
                      data={s}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Content>

      {/* Snippet Dialog View */}
      <SnippetDialogView
        open={snippetViewOpen}
        onClose={() => setSnippetViewOpen(false)}
        data={snippetRef ?? {}}
      />

      {/* Snippet Dialog Modify */}
      <SnippetDialogModify
        open={snippetModifyOpen}
        tags={tags}
        data={snippetRef ?? {}}
        onClose={() => setSnippetModifyOpen(false)}
        onSubmit={onSnippetDialogModify}
      />

      {/* Snippet Dialog Delete */}
      <SnippetDialogDelete
        onClose={() => {
          setSnippetDeleteOpen(false);
        }}
        open={snippetDeleteOpen}
        onSubmit={() => {
          setSnippetDeleteOpen(false);
          firebase?.snippet(snippetRef?.uid!).delete();
          enqueueSnackbar('Snippet deleted', {
            variant: 'success',
          });
        }}
      />

      {/* Floating Button*/}
      <Fab
        aria-label="Add"
        onClick={() => {
          setSnippetRef({});
          setSnippetModifyOpen(true);
        }}
        className={classes.fab}
        color={'primary'}
      >
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
  }),
);

export const Home = withAuthorization(
  (authUser) => !!authUser,
  HomeView,
);
