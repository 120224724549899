export const IQ_API_ENDPOINT = 'https://iq.eloq.swiss/api/rendering/';
export const IQ_API_KEY = '9af4d8381781baccb0f915e554f8798d';

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBt5Igr_MG74CgoHzMOoiXpIu2z8QKvNIE',
  authDomain: 'eloq-iq.firebaseapp.com',
  databaseURL: 'https://eloq-iq.firebaseio.com',
  projectId: 'eloq-iq',
  storageBucket: 'eloq-iq.appspot.com',
  messagingSenderId: '109728437711',
  appId: '1:109728437711:web:a50086ccdf403aae04aeba',
  measurementId: 'G-TK8ERNMHYM',
};
