import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import React from 'react';
import { Snippet } from './../../../models/Snippet';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(2),
    },
  });
});

interface Props {
  tags: string[];
  snippets: Snippet[];
  checkedTags: string[];
  onFilterChange: (checkedTags: string[]) => void;
}

export const SnippetFilter = (props: Props) => {
  const classes = useStyles();

  const handleFilterClick = (tag: string) => {
    if (props.checkedTags.length === props.tags.length) {
      props.onFilterChange([tag]);
    } else if (
      props.checkedTags.includes(tag) &&
      props.checkedTags.length === 1
    ) {
      props.onFilterChange([...props.tags]);
    } else if (props.checkedTags.includes(tag)) {
      props.onFilterChange(
        props.checkedTags.filter((m) => m !== tag),
      );
    } else {
      props.onFilterChange([...props.checkedTags, tag]);
    }
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography gutterBottom variant="h5" component="h2">
            Filters
          </Typography>
        </Grid>
        <Grid
          item
          xs
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={() => props.onFilterChange([...props.tags])}
            aria-label="clear filters"
          >
            Clear
          </Button>
        </Grid>
      </Grid>
      <FormGroup
        style={{
          overflowY: 'scroll',
          maxHeight: 600,
          flexWrap: 'nowrap',
        }}
      >
        {props.tags
          ?.sort((a, b) => a.localeCompare(b))
          .map((tag) => {
            return (
              <FormControlLabel
                key={tag}
                control={
                  <Checkbox
                    name={tag}
                    onChange={() => handleFilterClick(tag)}
                    checked={props.checkedTags.includes(tag)}
                  />
                }
                label={`${tag} (${
                  props.snippets.filter((m) => m.tags?.includes(tag))
                    .length
                })`}
              />
            );
          })}
      </FormGroup>
    </Paper>
  );
};
