import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React from 'react';

export interface IQDialogProps {
  open: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  title?: string;
  cancelText?: string;
  submitText?: string;
  children?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  classes?: Partial<ClassNameMap<string>>;
}

export const IQDialog = (props: IQDialogProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={props.maxWidth}
      classes={props.classes}
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        {props.cancelText && (
          <Button onClick={props.onClose} color="primary">
            {props.cancelText}
          </Button>
        )}
        {props.submitText && (
          <Button onClick={props.onSubmit} color="primary" autoFocus>
            {props.submitText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
