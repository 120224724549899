import {
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { IQAcfView } from '../../../components/IQAcfView';
import { IQDialog } from '../../../components/IQDialog';
import { IQEditor } from '../../../components/IQEditor';
import { IQCodePreview } from './../../../components/IQCodePreview/index';
import { TabPanel } from './../../../components/TabPanel/index';
import { Snippet } from './../../../models/Snippet';

interface Props {
  open: boolean;
  onClose: () => void;
  data: Snippet;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      minHeight: '90vh',
      maxHeight: '90vh',
    },
    tabsContainer: {
      marginTop: 30,
    },
    chipContainer: {
      textAlign: 'right',
    },
  }),
);

export const SnippetDialogView = (props: Props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');

  useEffect(() => {
    setValue(props.data.content?.php ? 'preview' : '');
  }, [props.data.content?.php]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: string,
  ) => {
    setValue(newValue);
  };

  const handleZipDownload = (event: React.ChangeEvent<{}>) => {
    var zip = new JSZip.default();

    // PHP
    if (props.data.content?.php)
      zip.file('template.php', props.data.content?.php);

    // CSS
    if (props.data.content?.css)
      zip.file('style.css', props.data.content?.css);

    // JS
    if (props.data.content?.javascript)
      zip.file('script.js', props.data.content?.javascript);

    // ACF
    if (props.data.content?.acfDefinition)
      zip.file('acf.json', props.data.content?.acfDefinition);

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      // see FileSaver.js
      saveAs(content, `${props.data.title}.zip`);
    });
  };

  return (
    <IQDialog
      maxWidth="lg"
      cancelText="Close"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <Typography variant="h5">{props.data.title}</Typography>
        </Grid>
        <Grid item sm={6} className={classes.chipContainer}>
          {props.data.tags?.map((tag) => {
            return <Chip key={tag} label={tag} />;
          })}
        </Grid>
      </Grid>

      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.tabsContainer}
      >
        {props.data.content?.php && (
          <Tab label="Preview" value="preview" />
        )}
        {props.data.content?.acfDefinition && (
          <Tab label="ACF Definition" value="acfDefinition" />
        )}
        {props.data.content?.php && <Tab label="PHP" value="php" />}
        {props.data.content?.css && <Tab label="CSS" value="css" />}
        {props.data.content?.javascript && (
          <Tab label="JAVASCRIPT" value="javascript" />
        )}
        {props.data.content?.markdown && (
          <Tab label="Markdown" value="markdown" />
        )}
      </Tabs>
      <TabPanel value={value} index="preview">
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={handleZipDownload}
            aria-label="download snippet"
          >
            Download Sippet
          </Button>
          <form
            action="https://iq.eloq.swiss/api/rendering/"
            method="POST"
            target="_blank"
            style={{ display: 'inline-block' }}
          >
            <input
              type="hidden"
              name="css"
              value={props.data.content?.css}
            />
            <input
              type="hidden"
              name="php"
              value={props.data.content?.php}
            />
            <input
              type="hidden"
              name="javascript"
              value={props.data.content?.javascript}
            />
            <input
              type="hidden"
              name="acf_definition"
              value={props.data.content?.acfDefinition}
            />
            <input
              type="hidden"
              name="api_key"
              value="9af4d8381781baccb0f915e554f8798d"
            />
            <Button type="submit" aria-label="open in new Tab">
              Open in new Tab
            </Button>
          </form>
        </div>
        <IQCodePreview
          php={props.data.content?.php}
          javascript={props.data.content?.javascript}
          css={props.data.content?.css}
          acfDefinition={props.data.content?.acfDefinition}
          style={{
            marginTop: 15,
            border: '1px solid rgba(0,0,0,0.15)',
          }}
        />
      </TabPanel>
      <TabPanel value={value} index="markdown">
        <ReactMarkdown
          children={props.data.content?.markdown ?? ''}
        />
      </TabPanel>
      <TabPanel value={value} index="acfDefinition">
        <Grid container>
          <Grid item sm={4}>
            <IQAcfView
              acfDefinition={props.data.content?.acfDefinition}
            />
          </Grid>
          <Grid item sm={8}>
            <IQEditor
              language="json"
              readonly={true}
              value={props.data.content?.acfDefinition}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index="php">
        <IQEditor
          language="php"
          readonly={true}
          value={props.data.content?.php}
        />
      </TabPanel>
      <TabPanel value={value} index="css">
        <IQEditor
          language="css"
          readonly={true}
          value={props.data.content?.css}
        />
      </TabPanel>
      <TabPanel value={value} index="javascript">
        <IQEditor
          language="javascript"
          readonly={true}
          value={props.data.content?.javascript}
        />
      </TabPanel>
    </IQDialog>
  );
};
