import React from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import { withAuthentication } from '../components/Session';
import * as ROUTES from '../constants/routes';
import { Home } from '../views/Home';
import { Editor } from './../views/Editor/index';
import { SignIn } from './../views/SignIn/index';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Route
          exact
          path="/"
          render={() => <Redirect to={ROUTES.HOME} />}
        />
        <Route path={ROUTES.SIGN_IN} component={SignIn} />
        <Route path={ROUTES.HOME} component={Home} />
        <Route path={ROUTES.EDITOR()} component={Editor} />
      </div>
    </BrowserRouter>
  );
}

export default withAuthentication(App);
