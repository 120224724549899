import {
  createStyles,
  makeStyles,
  SvgIcon,
  Theme,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';
import 'prismjs/themes/prism.css';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Editor from 'react-simple-code-editor';
import { prettify } from '../../utility/prettify';
import './style.css';

interface IQEditorProps {
  onValueChange?: (value: string) => void;
  value?: string;
  language: 'javascript' | 'css' | 'php' | 'json' | 'markdown';
  style?: React.CSSProperties;
  className?: string;
  readonly?: boolean;
  showLineNumbers?: boolean;
}

export const IQEditor = (props: IQEditorProps) => {
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.value ?? '');

    return () => {};
  }, [props.value]);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const onFormatCode = () => {
    const formattedCode = prettify(value, props.language);
    onValueChange(formattedCode);
  };

  const onValueChange = (v: string) => {
    setValue(v);
    if (props.onValueChange) props.onValueChange(v ?? '');
  };

  return (
    <div
      className={[classes.editorWrapper, props.className].join(' ')}
    >
      <Editor
        readOnly={props.readonly}
        value={value ?? ''}
        onValueChange={onValueChange}
        highlight={(code) => {
          const highlighted = highlight(
            code,
            languages[props.language],
            props.language,
          );
          return props.showLineNumbers
            ? highlighted
                .split('\n')
                .map(
                  (line, i) =>
                    `<span class='editorLineNumber'>${
                      i + 1
                    }</span>${line}`,
                )
                .join('\n')
            : highlighted;
        }}
        textareaId="codeArea"
        padding={20}
        className={
          classes.editorContainer +
          (props.showLineNumbers
            ? ' editorContainerWithLineNumbers'
            : '')
        }
        style={props.style ?? { minHeight: '40vh' }}
      />

      {!props.readonly && props.language !== 'markdown' && (
        <IconButton
          className={classes.btnFormat}
          onClick={onFormatCode}
        >
          <FormatIndentIncreaseIcon />
        </IconButton>
      )}

      {props.readonly && (
        <Tooltip
          onClose={handleTooltipClose}
          open={tooltipOpen}
          TransitionComponent={Zoom}
          leaveDelay={400}
          disableFocusListener
          disableTouchListener
          title="Copied to Clipboard"
          className={classes.btnCopy}
        >
          <CopyToClipboard
            text={props.value ?? ''}
            onCopy={handleTooltipOpen}
          >
            <IconButton>
              <SvgIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                </svg>
              </SvgIcon>
            </IconButton>
          </CopyToClipboard>
        </Tooltip>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnFormat: {
      position: 'absolute',
      top: 0,
      right: 0,
      cursor: 'pointer',
    },
    btnCopy: {
      position: 'absolute',
      top: 0,
      right: 0,
      cursor: 'pointer',
    },
    editorWrapper: {
      position: 'relative',
    },
    editorContainer: {
      borderRadius: 3,
      backgroundColor: '#fafafa',
      fontVariantLigatures: 'common-ligatures',
      fontSize: 14,
      lineHeight: 1.5,
      fontFamily:
        "'Fira code', 'Fira Mono', Consolas, Menlo, Courier",
      '& textarea': {
        outline: 0,
      },
    },
  }),
);
