/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStyles,
  Fab,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Content } from '../../components/Content';
import { FirebaseContext } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { HOME } from '../../constants/routes';
import { IQCodePreview } from './../../components/IQCodePreview/index';
import { IQEditor } from './../../components/IQEditor/index';
import { TabPanel } from './../../components/TabPanel/index';
import { Snippet } from './../../models/Snippet';

interface Props {}
export type EditorViewRouteParams = {
  uid: string;
};
const EditorView = (props: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let { uid } = useParams<EditorViewRouteParams>();
  const firebase = useContext(FirebaseContext);
  const [snippet, setSnippet] = useState<Snippet>({});
  const history = useHistory();
  const [fullscreenPreview, setFullscreenPreview] = useState(false);
  const [php, setPhp] = useState('');
  const [css, setCss] = useState('');
  const [javascript, setJavascript] = useState('');
  const [tabValue, setTabValue] = React.useState('php');
  const [changeTracker, setChangeTracker] = useState(false);

  useEffect(() => {
    firebase
      ?.snippet(uid)
      .get()
      .then((doc) => {
        setSnippet({ ...doc.data(), uid: doc.id });
      })
      .catch((err) => {});
  }, [firebase, uid]);

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: string,
  ) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setPhp(snippet?.content?.php ?? '');
    setCss(snippet?.content?.css ?? '');
    setJavascript(snippet?.content?.javascript ?? '');
  }, [snippet]);

  const handlePhpChange = useCallback(
    _.debounce((q) => [setPhp(q), setChangeTracker(true)], 750),
    [],
  );

  const handleCssChange = useCallback(
    _.debounce((q) => [setCss(q), setChangeTracker(true)], 750),
    [],
  );

  const handleJavascriptChange = useCallback(
    _.debounce(
      (q) => [setJavascript(q), setChangeTracker(true)],
      750,
    ),
    [],
  );

  const keyMap = { SHOW_ALL_HOTKEYS: 'shift+a' };
  const handlers = {
    SHOW_ALL_HOTKEYS: () => setFullscreenPreview((m) => !m),
  };

  if (!uid == null) return <Redirect to={HOME} />;

  return (
    <React.Fragment>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />;
      <Content>
        <Grid container spacing={3} justify="flex-end">
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<CloseIcon />}
              onClick={() => {
                if (
                  !changeTracker ||
                  window.confirm(
                    'You have unsaved changes, sure you want exit?',
                  )
                ) {
                  history.push(HOME);
                }
              }}
            >
              Close
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              size="small"
              variant="contained"
              disabled={!changeTracker}
              startIcon={<SaveIcon />}
              onClick={() => {
                if (snippet && snippet.uid) {
                  firebase
                    ?.snippet(snippet.uid)
                    .update({
                      'content.php': php,
                      'content.css': css,
                      'content.javascript': javascript,
                    })
                    .then(() => {
                      setChangeTracker(false);
                      enqueueSnackbar('Snippet updated', {
                        variant: 'success',
                      });
                    })
                    .catch((err) => {
                      enqueueSnackbar(err, {
                        variant: 'error',
                      });
                    });
                }
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="PHP" value="php" />
          <Tab label="CSS" value="css" />
          <Tab label="JAVASCRIPT" value="javascript" />
        </Tabs>
        <Grid container spacing={3}>
          <Grid
            item
            xl={6}
            style={{
              height: '90vh',
              display: fullscreenPreview ? 'none' : 'block',
            }}
          >
            <TabPanel value={tabValue} index={'php'} padding={0}>
              <IQEditor
                language="php"
                value={snippet?.content?.php}
                onValueChange={handlePhpChange}
                showLineNumbers={true}
                className={classes.overflowScroll}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={'css'} padding={0}>
              <IQEditor
                language="css"
                value={snippet?.content?.css}
                onValueChange={handleCssChange}
                showLineNumbers={true}
                className={classes.overflowScroll}
              />
            </TabPanel>
            <TabPanel
              value={tabValue}
              index={'javascript'}
              padding={0}
            >
              <IQEditor
                language="javascript"
                value={snippet?.content?.javascript}
                onValueChange={handleJavascriptChange}
                showLineNumbers={true}
                className={classes.overflowScroll}
              />
            </TabPanel>
          </Grid>
          <Grid
            item
            xl={fullscreenPreview ? 12 : 6}
            style={{ height: '90vh' }}
          >
            <IQCodePreview
              acfDefinition={snippet?.content?.acfDefinition}
              css={css}
              php={php}
              javascript={javascript}
              scrolling={true}
              className={classes.overflowScroll}
            />
          </Grid>
        </Grid>
      </Content>
      {/* Floating Button*/}
      <Fab
        aria-label="Add"
        onClick={() => {
          setFullscreenPreview((m) => !m);
        }}
        className={classes.fab}
        color={'primary'}
      >
        <ZoomOutMapIcon />
      </Fab>
    </React.Fragment>
  );
};

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    overflowScroll: {
      overflow: 'scroll',
      height: '80vh !important',
      maxHeight: '80vh !important',
      minHeight: '80vh !important',
    },
  }),
);

export const Editor = withAuthorization(
  (authUser) => !!authUser,
  EditorView,
);
