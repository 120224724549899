import { Tooltip } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ImageIcon from '@material-ui/icons/Image';
import LinkIcon from '@material-ui/icons/Link';
import MoneyIcon from '@material-ui/icons/Money';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import PostAddIcon from '@material-ui/icons/PostAdd';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RepeatIcon from '@material-ui/icons/Repeat';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ShortTextIcon from '@material-ui/icons/ShortText';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import TextureIcon from '@material-ui/icons/Texture';
import WidgetsIcon from '@material-ui/icons/Widgets';
import { Alert } from '@material-ui/lab';
import React, { MouseEventHandler } from 'react';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    nested2x: {
      paddingLeft: 2 * theme.spacing(4),
    },
  }),
);

interface IQAcfViewProps {
  acfDefinition: string | undefined;
}

interface AcfIconProps {
  type: string;
}

const AcfIcon = (props: AcfIconProps) => {
  switch (props.type) {
    case 'radio':
      return <RadioButtonCheckedIcon />;
    case 'repeater':
      return <RepeatIcon />;
    case 'wysiwyg':
      return <CodeIcon />;
    case 'date_picker':
      return <CalendarTodayIcon />;
    case 'time_picker':
      return <ScheduleIcon />;
    case 'text':
      return <ShortTextIcon />;
    case 'textarea':
      return <TextFieldsIcon />;
    case 'image':
      return <ImageIcon />;
    case 'link':
      return <LinkIcon />;
    case 'gallery':
      return <PermMediaIcon />;
    case 'number':
      return <MoneyIcon />;
    case 'file':
      return <AttachmentIcon />;
    case 'post_object':
      return <PostAddIcon />;
    case 'flexible_content':
      return <WidgetsIcon />;
    case 'layout':
      return <DashboardIcon />;
    default:
      return <TextureIcon />;
  }
};

interface AcfFieldListItemProps extends ListItemProps {
  data: any;
  children?: JSX.Element | JSX.Element[];
  onClick?: MouseEventHandler;
  className?: string;
  button?: boolean;
}

const AcfFieldListItem = (props: AcfFieldListItemProps) => {
  const { data, onClick, className } = props;
  return (
    <ListItem
      // @ts-expect-error
      button={props.button ? true : false}
      onClick={onClick}
      className={className}
    >
      <Tooltip title={data.type}>
        <ListItemIcon>
          <AcfIcon type={data.type} />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={data.label} />
      {props.children}
    </ListItem>
  );
};

export const IQAcfView = (props: IQAcfViewProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const renderFieldWithSubFields = (f) => {
    return (
      <div>
        <AcfFieldListItem data={f} onClick={handleClick} button>
          {open ? <ExpandLess /> : <ExpandMore />}
        </AcfFieldListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {f.sub_fields.map((sf) => {
              let retVal: JSX.Element[] = [];
              retVal.push(
                <AcfFieldListItem
                  data={sf}
                  className={classes.nested}
                />,
              );

              if (sf.sub_fields) {
                retVal.push(
                  <List component="div" disablePadding>
                    {sf.sub_fields.map((sf2x) => {
                      return (
                        <AcfFieldListItem
                          data={sf2x}
                          className={classes.nested2x}
                        />
                      );
                    })}
                  </List>,
                );
              }

              return retVal;
            })}
          </List>
        </Collapse>
      </div>
    );
  };

  const renderFlexibleContentField = (f) => {
    return (
      <div>
        <AcfFieldListItem data={f} onClick={handleClick} button>
          {open ? <ExpandLess /> : <ExpandMore />}
        </AcfFieldListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(f.layouts).map((keyName) => {
              return (
                <div>
                  <AcfFieldListItem
                    data={{
                      ...f.layouts[keyName],
                      type: 'layout',
                    }}
                    className={classes.nested}
                  />
                  <List component="div" disablePadding>
                    {f.layouts[keyName].sub_fields.map((sf) => {
                      return (
                        <AcfFieldListItem
                          data={sf}
                          className={classes.nested2x}
                        />
                      );
                    })}
                  </List>
                </div>
              );
            })}
          </List>
        </Collapse>
      </div>
    );
  };

  const AcfFieldList = () => {
    try {
      return JSON.parse(props.acfDefinition ?? '').fields.map((f) => {
        if (f.sub_fields) {
          return renderFieldWithSubFields(f);
        } else if (f.type === 'flexible_content') {
          return renderFlexibleContentField(f);
        } else {
          return <AcfFieldListItem data={f} />;
        }
      });
    } catch {
      return <Alert severity="warning">Invalid JSON!</Alert>;
    }
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          ACF Definition
        </ListSubheader>
      }
      className={classes.root}
    >
      <AcfFieldList />
    </List>
  );
};
