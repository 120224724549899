import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { FIREBASE_CONFIG } from './../../constants/config';

class Firebase {
  auth: app.auth.Auth;
  db: app.firestore.Firestore;

  constructor() {
    app.initializeApp(FIREBASE_CONFIG);
    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.firestore();
  }

  // *** Auth API ***

  /*doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
*/

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordUpdate = (password: string) =>
    this.auth.currentUser?.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (
    next: (authUser: app.User) => void,
    fallback: () => void,
  ) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        /*     this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // merge auth and db user
            if (authUser !== null) {
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser,
              };
            }
             });
      */
        next(authUser);
      } else {
        fallback();
      }
    });

  // *** Snippet API ***

  snippet = (uid: string) => this.db.doc(`snippets/${uid}`);

  snippets = () => this.db.collection('snippets');
}

export default Firebase;
