import {
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { IQAcfView } from '../../../components/IQAcfView';
import { IQDialog } from '../../../components/IQDialog';
import { IQEditor } from '../../../components/IQEditor';
import { TabPanel } from '../../../components/TabPanel';
import { Snippet } from './../../../models/Snippet';

interface Props {
  open: boolean;
  onSubmit: (data: Snippet) => void;
  onClose: () => void;
  data: Snippet;
  tags: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      minHeight: '90vh',
      maxHeight: '90vh',
    },
    tabsContainer: {
      marginTop: 20,
    },
  }),
);

export const SnippetDialogModify = (props: Props) => {
  const classes = useStyles();
  const [snippet, setSnippet] = React.useState(props.data);
  const [tabValue, setTabValue] = React.useState('acfDefinition');

  useEffect(() => {
    setSnippet(props.data);
    return () => {};
  }, [props.data]);

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: string,
  ) => {
    setTabValue(newValue);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setSnippet({
      ...snippet,
      [name]: value,
    });
  };

  const handleCodeChange = (lang: string, value: string) => {
    setSnippet({
      ...snippet,
      content: {
        ...snippet.content,
        [lang]: value,
      },
    });
  };

  return (
    <IQDialog
      cancelText="Close"
      submitText="Save"
      maxWidth="lg"
      title={props.data.uid ? 'Edit Snippet' : 'Create Snippet'}
      open={props.open}
      onClose={props.onClose}
      onSubmit={() => props.onSubmit(snippet)}
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <TextField
            label="Title"
            name="title"
            variant="outlined"
            fullWidth={true}
            value={snippet?.title}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item sm={6}>
          <Autocomplete
            multiple
            options={props.tags ?? []}
            defaultValue={snippet?.tags}
            freeSolo
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            onChange={(m, value) => {
              setSnippet({
                ...snippet,
                tags: value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Tags"
                placeholder="Tags"
              />
            )}
          />
        </Grid>
      </Grid>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={classes.tabsContainer}
      >
        <Tab label="ACF Definition" value="acfDefinition" />
        <Tab label="PHP" value="php" />
        <Tab label="CSS" value="css" />
        <Tab label="JAVASCRIPT" value="javascript" />
        <Tab label="Markdown" value="markdown" />
      </Tabs>
      <TabPanel value={tabValue} index={'markdown'}>
        <IQEditor
          language="markdown"
          value={snippet.content?.markdown ?? ''}
          onValueChange={(value) =>
            handleCodeChange('markdown', value)
          }
        />
      </TabPanel>
      <TabPanel value={tabValue} index={'acfDefinition'}>
        <Grid container>
          <Grid item sm={4}>
            <IQAcfView
              acfDefinition={snippet.content?.acfDefinition}
            />
          </Grid>
          <Grid item sm={8}>
            <IQEditor
              language="json"
              value={snippet.content?.acfDefinition ?? ''}
              onValueChange={(value) =>
                handleCodeChange('acfDefinition', value)
              }
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={'php'}>
        <IQEditor
          language="php"
          value={snippet.content?.php}
          onValueChange={(value) => handleCodeChange('php', value)}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={'css'}>
        <IQEditor
          language="css"
          value={snippet.content?.css}
          onValueChange={(value) => handleCodeChange('css', value)}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={'javascript'}>
        <IQEditor
          language="javascript"
          value={snippet.content?.javascript}
          onValueChange={(value) =>
            handleCodeChange('javascript', value)
          }
        />
      </TabPanel>
    </IQDialog>
  );
};
