import app from 'firebase/app';
import React from 'react';
import { withFirebase } from '../Firebase';
import { withFirebaseProps } from './../Firebase/withFirebase';
import AuthUserContext from './context';

interface withAuthenticationProps {}

interface withAuthenticationState {
  authUser: null | app.User;
}

function withAuthentication<T extends withAuthenticationProps>(
  Component: React.ComponentType<T>,
) {
  class WithAuthentication extends React.Component<
    T & withFirebaseProps,
    withAuthenticationState
  > {
    listener!: () => void;
    constructor(props: T & withFirebaseProps) {
      super(props);

      const authUser = localStorage.getItem('authUser');

      this.state = {
        authUser:
          typeof authUser === 'string'
            ? (JSON.parse(authUser) as app.User)
            : null,
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser) => {
          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.setState({ authUser });
        },
        () => {
          localStorage.removeItem('authUser');
          this.setState({ authUser: null });
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
}

export default withAuthentication;
