import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: 30,
    },
  }),
);

export const Content: FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles();
  return <main className={classes.root}>{children}</main>;
};
